.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-box{
  --pc-box-border-color: var(--p-color-border-subdued);
  --pc-box-border-style: solid;
  --pc-box-border-block-start-width: var(--p-border-width-1);
  --pc-box-min-height: var(--p-space-16);
  --pc-box-padding-block-end-xs: var(--p-space-4);
  --pc-box-padding-block-start-xs: var(--p-space-4);
  --pc-box-padding-inline-start-xs: var(--p-space-5);
  --pc-box-padding-inline-end-xs: var(--p-space-5);
  --pc-box-width: 100%;
}
.custom-horizontalstack{
  --pc-horizontal-stack-block-align: center; 
  --pc-horizontal-stack-wrap: wrap; 
  --pc-horizontal-stack-gap-xs: var(--p-space-4);
}